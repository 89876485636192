import React from 'react';
import { IconTypes } from '../../../../models/Icons/IconModels';

export const IconEdit = ({ active = false, size = 20 }: IconTypes) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5244 0.046875C12.4131 0.0703125 12.1934 0.149414 12.041 0.222656L11.7627 0.357422L8.05371 4.06934L4.3418 7.77832L3.78223 9.46289C3.46289 10.4297 3.22266 11.1943 3.22266 11.2559C3.22266 11.5459 3.4541 11.7773 3.74121 11.7773C3.80859 11.7773 4.54102 11.5488 5.53711 11.2178L7.22168 10.6582L10.9307 6.94629L14.6426 3.2373L14.7773 2.95898C15.0732 2.34375 15.0732 1.75488 14.7744 1.14258C14.6631 0.911133 14.5986 0.823242 14.3877 0.612305C14.1768 0.398438 14.0918 0.336914 13.8574 0.225586C13.4092 0.0117188 12.9814 -0.046875 12.5244 0.046875ZM13.3594 1.1748C13.5527 1.26855 13.7197 1.42676 13.8223 1.62598C13.8896 1.75488 13.9014 1.80762 13.9014 2.05078C13.9014 2.28809 13.8896 2.35254 13.8311 2.47559C13.7725 2.59277 13.0283 3.35156 10.2188 6.16699L6.67969 9.71191L5.64844 10.0547C5.08301 10.2451 4.61426 10.3945 4.6084 10.3916C4.60547 10.3857 4.75488 9.91992 4.93945 9.35742L5.2793 8.33496L8.74219 4.86914C10.6436 2.96191 12.252 1.3623 12.3135 1.3125C12.375 1.2627 12.501 1.18945 12.5918 1.15137C12.8203 1.05762 13.1279 1.06641 13.3594 1.1748Z"
        fill="currentColor"
      />
      <path
        d="M1.18945 1.12789C0.776369 1.24215 0.39258 1.54977 0.19922 1.92184C-0.014647 2.32906 -0.00292828 1.88961 0.00586079 8.12106L0.0146498 13.7255L0.0908217 13.9218C0.131837 14.0302 0.210939 14.1826 0.260744 14.2646C0.4834 14.6103 0.922853 14.9033 1.31836 14.9707C1.45313 14.9941 2.98242 15 6.54785 14.9941L11.5869 14.9853L11.7891 14.9062C12.3223 14.6982 12.7061 14.2558 12.8174 13.7168C12.8555 13.5351 12.8613 13.1045 12.8555 10.6816L12.8467 7.86324L12.7764 7.75777C12.6299 7.54684 12.375 7.45309 12.1494 7.52633C12.0176 7.57027 11.8506 7.75484 11.8096 7.90133C11.7891 7.97457 11.7773 8.91207 11.7773 10.7578C11.7773 12.624 11.7686 13.5293 11.7451 13.5849C11.707 13.6845 11.584 13.8193 11.4785 13.875C11.4111 13.9101 10.6494 13.916 6.42774 13.916H1.45606L1.33594 13.8457C1.26856 13.8076 1.18652 13.7226 1.14844 13.6582L1.08399 13.541L1.08985 8.0068L1.09863 2.46969L1.1836 2.36129C1.23047 2.29977 1.32129 2.22359 1.38867 2.1943C1.50293 2.14156 1.64942 2.13863 4.8252 2.13863C7.0957 2.13863 8.16797 2.12984 8.22363 2.10641C8.41992 2.03316 8.55762 1.83102 8.56055 1.61422C8.56055 1.41793 8.46973 1.25094 8.31153 1.15719L8.18262 1.08395L4.75781 1.08688C1.97461 1.08981 1.30664 1.09566 1.18945 1.12789Z"
        fill="currentColor"
      />
    </svg>
  );
};
